import React, { useState, useContext } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useRouter } from 'next/router'

import { useSnackbar } from 'notistack'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import WatchIcon from '@material-ui/icons/VisibilityOutlined'
import SellIcon from '@material-ui/icons/AttachMoney'
import ModifyIcon from '@material-ui/icons/BorderColorOutlined'
import TrashIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/GetApp'
import EditLocation from '@material-ui/icons/EditLocation'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import Favorite from '@material-ui/icons/Favorite'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ConfirmationDialog from '../ConfirmationDialog'
import LoadingAnimation from '../LoadingAnimation'
import SimpleMenu from '../SimpleMenu'
import { SET_FAVORITES_LIST } from '../../providers/UserProjectsProvider/action-types'

import { DELETE_USER_TEMPLATE } from '../../graphql/userTemplates'
import { moveUserTemplateMutation } from '../../graphql/userLibrary'
import { toggleFavoriteMutation } from '../../graphql/templates'
import { UserProjectsContext } from '../../providers/UserProjectsProvider'
import {
  UserTemplateActionsContainer,
  ActionButton
} from './UserTemplateActions.styles'
import { getLoggedInUser } from '../../graphql/account'
import UserLibraryMoveView from '../UserLibraryMoveView'
import { UserLibraryContext } from '../../providers/UserLibraryProvider'

const UserTemplateActions = ({
  id,
  type,
  status,
  toggleSalesDialog,
  toggleCloneDialog,
  templateId,
  dispatch,
  templateName,
  renderUrl,
  refetch
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    variablesState: { favoritesList }
  } = useContext(UserProjectsContext)

  const [isConfirmationDialogOpen, toggleConfirmationDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [moveDialog, toggleMoveDialog] = useState(false)

  const {
    state: { moveDirectory: currentLibraryDirectory }
  } = useContext(UserLibraryContext)
  const {
    data: { me }
  } = useQuery(getLoggedInUser, {
    ssr: false,
    fetchPolicy: 'cache-only'
  })

  const [deleteUserTemplate, { loading }] = useMutation(DELETE_USER_TEMPLATE, {
    variables: { ids: [id] },
    onCompleted: () => {
      refetch()
      toggleConfirmationDialog(false)
    },
    awaitRefetchQueries: true,
    onError: (e) => {
      enqueueSnackbar(e.message, {
        variant: 'error'
      })
      toggleConfirmationDialog(false)
    }
  })
  const [toggleFavorite] = useMutation(toggleFavoriteMutation, {
    onCompleted: (data) => {
      if (data.toggleFavorite.favorited) {
        dispatch({
          type: SET_FAVORITES_LIST,
          payload: favoritesList.concat(data.toggleFavorite.id)
        })
      } else {
        dispatch({
          type: SET_FAVORITES_LIST,
          payload: favoritesList.filter((i) => i !== data.toggleFavorite.id)
        })
      }
    },
    onError: (e) => console.log('error', e)
  })

  const [moveTemplate, { loading: moveTemplateLoading }] = useMutation(
    moveUserTemplateMutation,
    {
      onCompleted: async () => {
        enqueueSnackbar('Move Successful!', { variant: 'success' })
        toggleMoveDialog(false)
      },
      onError: () => {
        enqueueSnackbar('Move Failed!', { variant: 'error' })
        toggleMoveDialog(false)
      }
    }
  )

  const { push } = useRouter()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlefavoriteAction = async () => {
    await toggleFavorite({ variables: { id: templateId } })
  }

  const handleMove = () => {
    toggleMoveDialog(true)
  }

  const handleCloseMove = () => {
    toggleMoveDialog(false)
  }

  const handleTemplateMove = () => {
    moveTemplate({
      variables: {
        filePath: renderUrl,
        targetPath: currentLibraryDirectory
      }
    })
  }
  const menuItems = [
    ...(status === 'SUCCESSFUL'
      ? [
          {
            label: 'Watch',
            icon: WatchIcon,
            action: () => push(`/templates/watch/${id}`)
          },
          {
            label: 'Download',
            icon: DownloadIcon,
            action: () => push(renderUrl)
          },
          ...(me.salesAccount
            ? [
                {
                  label: 'Create Sales Project',
                  icon: SellIcon,
                  action: () => toggleSalesDialog(true)
                }
              ]
            : []),
          {
            label: 'Move To Library',
            icon: EditLocation,
            action: () => handleMove()
          },
          {
            label: 'Clone Project',
            icon: FileCopyIcon,
            action: () => toggleCloneDialog(true)
          }
        ]
      : []),
    {
      label: 'Modify Project',
      icon: ModifyIcon,
      action: () => push(`/editor/${type.slug.replace('-', '/')}/${id}`)
    },
    {
      label: 'Move To Trash',
      icon: TrashIcon,
      action: () => toggleConfirmationDialog(true)
    },
    ...(templateName !== 'Blank Image Template'
      ? [
          {
            label: favoritesList?.includes(templateId)
              ? 'Remove From Favorites'
              : 'Add To Favorites',
            icon: favoritesList?.includes(templateId)
              ? Favorite
              : FavoriteBorder,
            action: () => handlefavoriteAction()
          }
        ]
      : [])
  ]
  return (
    <UserTemplateActionsContainer>
      <ActionButton
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreIcon />
      </ActionButton>
      <SimpleMenu
        handleClose={handleClose}
        menuItems={menuItems}
        anchorEl={anchorEl}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        onClose={() => toggleConfirmationDialog(!isConfirmationDialogOpen)}
        message='Are you sure you would like to delete this project and all its progress?'
        actionLabel={loading ? <LoadingAnimation /> : 'Delete'}
        disableAction={loading}
        onConfirm={deleteUserTemplate}
      />
      {moveDialog && (
        <ConfirmationDialog
          open={moveDialog}
          onClose={handleCloseMove}
          message='Please browse into the new path for the selected assets(s)'
          title='Move Assets(s)'
          actionLabel='Move, Here'
          onConfirm={handleTemplateMove}
          component={UserLibraryMoveView}
          loading={moveTemplateLoading}
        />
      )}
    </UserTemplateActionsContainer>
  )
}
export default UserTemplateActions
