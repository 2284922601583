import { userProjectToggle } from '../../lib/constants/userTemplate'

const templatesListVariables = {
  search: undefined,
  type: undefined,
  orderBy: 'createdAt_DESC',
  currentStatusValues: userProjectToggle.drafts.values,
  currentStatusLabel: userProjectToggle.drafts.label,
  favoritesList: [],
  showDraft: false,
  directoryView: 'grid',
  currentDirectory: {
    id: '',
    name: '',
    path: '',
    type: 'folders',
    directoryData: []
  },
  selectedItems: [],
  trashItems: [],
  moveDirectory: '',
  sortQuery: null,
  moveToPath: null,
  drafts: true,
  confirmDelete: false,
  confirmRestore: false
}

export default templatesListVariables
