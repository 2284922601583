export const SET_SEARCH = 'SET_SEARCH'
export const SET_ORDER_BY = 'SET_ORDER_BY'
export const SET_TYPE = 'SET_TYPE'
export const SET_STATUSES = 'SET_STATUSES'
export const SELECT_ITEM = 'SELECT_ITEM'
export const UNSELECT_ITEM = 'UNSELECT_ITEM'
export const CLEAR_SELECTED_ITEMS = 'CLEAR_SELECTED_ITEMS'
export const SET_FAVORITES_LIST = 'SET_FAVORITES_LIST'
export const CHANGE_DIRECTORY_VIEW = 'CHANGE_DIRECTORY_VIEW'
export const CHANGE_SEARCH_TERM = 'CHANGE_SEARCH_TERM'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_CURRENT_DIRECTORY = 'CHANGE_CURRENT_DIRECTORY'
export const OPEN_CONFIRM_DELETE = 'OPEN_CONFIRM_DELETE'
export const CLOSE_CONFIRM_DELETE = 'CLOSE_CONFIRM_DELETE'
export const OPEN_CONFIRM_MOVE = 'OPEN_CONFIRM_MOVE'
export const CLOSE_CONFIRM_MOVE = 'CLOSE_CONFIRM_MOVE'
export const CHANGE_PROJECTS_MOVE_PATH = 'CHANGE_PROJECTS_MOVE_PATH'
export const SET_DRAFTS = 'SET_DRAFTS'
export const SET_SHOW_TRASH = 'SET_SHOW_TRASH'
export const OPEN_CONFIRM_RESTORE = 'OPEN_CONFIRM_RESTORE'
export const CLOSE_CONFIRM_RESTORE = 'CLOSE_CONFIRM_RESTORE'
export const CLEAR_TRASH_ITEMS = 'CLEAR_TRASH_FILES'
