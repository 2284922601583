import React, { useState } from 'react'
import Router from 'next/router'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { useQuery } from '@apollo/react-hooks'
import Image from '@material-ui/icons/Image'
import Videocam from '@material-ui/icons/Videocam'
import { CircularProgress } from '@material-ui/core'
import { getLoggedInUser } from '../../graphql/account'
import UserTemplateActions from '../UserTemplateActions'
// import {
//   UNSELECT_ITEM,
//   SELECT_ITEM
// } from '../../providers/UserProjectsProvider/action-types'

import {
  UserTemplateContainer,
  SlideChild,
  UserTemplateOverLay
} from './UserTemplate.styles'
import SalesDialog from '../SalesDialog'
import CloneDialog from '../CloneDialog'
import { RENDERING_STATUSES } from '../../lib/constants/userTemplate'

const UserTemplate = ({
  data,
  dispatch,
  selectedProject,
  disableActions,
  onSelectChange,
  selected,
  refetch
}) => {
  const {
    thumbnailUrl,
    name,
    type,
    id,
    renderUrl,
    currentStatus: { status },
    template: { id: templateId, name: templateName }
  } = data

  const {
    data: { me }
  } = useQuery(getLoggedInUser, {
    ssr: false,
    fetchPolicy: 'cache-only'
  })
  const [showMenuIcon, setShowMenuIcon] = useState(false)
  const [isSalesDialogOpen, toggleSalesDialog] = useState(false)
  const [isCloneDialogOpen, toggleCloneDialog] = useState(false)

  const onTemplateEditClick = () => {
    const mainRoute = status === 'SUCCESSFUL' ? '/templates' : '/editor'
    const route =
      status === 'SUCCESSFUL'
        ? `/templates/watch/${id}`
        : `/editor/${type.slug.replace('-', '/')}/${id}`
    Router.push(mainRoute, route)
  }

  // const handleChange = useCallback((e) => {
  //   if (e.target.checked) {
  //     dispatch({
  //       type: SELECT_ITEM,
  //       payload: id
  //     })
  //   } else {
  //     dispatch({
  //       type: UNSELECT_ITEM,
  //       payload: id
  //     })
  //   }
  // }, [])

  return (
    <UserTemplateContainer
      onMouseEnter={() => setShowMenuIcon(true)}
      onMouseLeave={() => setShowMenuIcon(false)}
    >
      <Checkbox
        style={{ top: 0, left: 0, position: 'absolute', zIndex: '999' }}
        checked={selectedProject}
        onChange={() => onSelectChange(selected)}
      />
      <img src={thumbnailUrl} width='100%' alt={name} />
      <Box p={1}>
        <Typography variant='subtitle2'>{name}</Typography>
        {status === 'FAILED' && (
          <Typography variant='caption' color='secondary'>
            Render failed, please try again
          </Typography>
        )}
        <Box justifyContent='flex-end' display='flex'>
          {type.typeName === 'UserImageTemplate' ? (
            <Image color='disabled' fontSize='small' />
          ) : (
            <Videocam color='disabled' fontSize='small' />
          )}
        </Box>
      </Box>
      {disableActions && (
        <Slide direction='left' in={showMenuIcon} mountOnEnter unmountOnExit>
          <SlideChild>
            <UserTemplateActions
              id={id}
              type={type}
              status={status}
              toggleSalesDialog={toggleSalesDialog}
              toggleCloneDialog={toggleCloneDialog}
              templateId={templateId}
              templateName={templateName}
              dispatch={dispatch}
              renderUrl={renderUrl}
              refetch={refetch}
            />
          </SlideChild>
        </Slide>
      )}
      <Fade in={showMenuIcon || RENDERING_STATUSES.includes(status)}>
        <UserTemplateOverLay onClick={() => onTemplateEditClick()}>
          {RENDERING_STATUSES.includes(status) && (
            <>
              <CircularProgress color='inherit' />
              <Typography variant='button'>Processing...</Typography>
            </>
          )}
        </UserTemplateOverLay>
      </Fade>
      {me.salesAccount && (
        <SalesDialog
          open={isSalesDialogOpen}
          onClose={() => toggleSalesDialog(false)}
          userTemplateId={id}
        />
      )}
      <CloneDialog
        open={isCloneDialogOpen}
        onClose={() => toggleCloneDialog(false)}
        userTemplateId={id}
        type={type}
        refetch={refetch}
      />
    </UserTemplateContainer>
  )
}

UserTemplate.propTypes = {
  data: PropTypes.object
}

UserTemplate.defaultProps = {
  data: {}
}
export default UserTemplate
