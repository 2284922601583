/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Router from 'next/router'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import { Button } from '@material-ui/core/'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  moveFilesMutation,
  moveFoldersMutation,
  restoreFoldersMutation,
  restoreFilesMutation
} from '../../graphql/userLibrary'
import {
  projetsMoveFilesMutation,
  projectsMoveFoldersMutation,
  getBasicUserTemplates
} from '../../graphql/userTemplates'
import LibraryFilesGrid from '../LibraryFilesGrid'
import LibraryFilesList from '../LibraryFilesList'
import LibrarySortFilter from '../LibrarySortFilter'
import ConfirmationDialog from '../ConfirmationDialog'
import { LibraryContainer } from './UserLibrary.styles'
import {
  CLOSE_CONFIRM_DELETE,
  CHANGE_CURRENT_DIRECTORY,
  CLOSE_CONFIRM_MOVE,
  CLOSE_CONFIRM_RESTORE,
  CLEAR_SELECTED_ITEMS,
  CLEAR_TRASH_ITEMS
} from '../../providers/UserLibraryProvider/actions-types'
import UserLibraryMoveView from '../UserLibraryMoveView'
import UserProjectsMoveView from '../UserProjectsMoveView'
import LibraryBreadCrumbs from '../LibraryBreadCrumbs'
import { partnerList, listLinks } from '../../lib/constants/partnerList'
import { userProjectToggle } from '../../lib/constants/userTemplate'
import * as actions from '../../providers/UserProjectsProvider/action-types'

const LibraryFiles = ({
  items,
  loading,
  refetch,
  state,
  dispatch,
  libraryType,
  handleDelete,
  deleteFilesLoading,
  deleteFoldersLoading,
  role,
  renameProject
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [filesWithTemplates, setFilesWithTemplates] = useState([])
  const {
    currentDirectory,
    directoryView,
    selectedItems,
    trash,
    showTrash,
    trashItems,
    confirmDelete,
    confirmMove,
    moveDirectory,
    confirmRestore,
    filters
  } = state

  const ids =
    libraryType === 'userProjects'
      ? items?.files?.map((file) => {
          const cutLength = file.extension.length
          return file.name.slice(0, -cutLength)
        })
      : []

  const { loading: projectsLoading } = useQuery(getBasicUserTemplates, {
    variables: {
      ids: ids || [],
      currentStatusOptions: currentDirectory.path.includes('drafts')
        ? userProjectToggle.drafts.values
        : userProjectToggle.published.values
    },
    fetchPolicy: 'network-only',
    skip: !ids?.length,
    onCompleted: (data) => {
      const updatedFilesWithTemplates = items?.files?.reduce(
        (filteredFiles, file) => {
          const templateId = file.name.slice(0, -file.extension.length)
          const template = data?.basicUserTemplates?.find(
            (t) => t.id === templateId
          )
          if (template) {
            filteredFiles.push({ ...file, template })
          }
          return filteredFiles
        },
        []
      )
      if (updatedFilesWithTemplates.length === 0) {
        setFilesWithTemplates([])
      } else {
        setFilesWithTemplates(updatedFilesWithTemplates)
      }
    }
  })
  const updatedItems =
    libraryType === 'userProjects'
      ? {
          ...items,
          files: filesWithTemplates || items?.files
        }
      : items

  const [moveFiles, { loading: moveFilesLoading }] = useMutation(
    libraryType === 'userProjects'
      ? projetsMoveFilesMutation
      : moveFilesMutation,
    {
      onCompleted: async () => {
        refetch()
        enqueueSnackbar('Move Successful!', { variant: 'success' })
        dispatch({ type: CLOSE_CONFIRM_MOVE })
        dispatch({
          type:
            libraryType === 'userProjects'
              ? actions.CLEAR_SELECTED_ITEMS
              : CLEAR_SELECTED_ITEMS
        })
      },
      awaitRefetchQueries: true,
      onError: () => {
        enqueueSnackbar('Move Failed!', { variant: 'error' })
        dispatch({ type: CLOSE_CONFIRM_MOVE })
      }
    }
  )

  const [moveFolders, { loading: moveFoldersLoading }] = useMutation(
    libraryType === 'userProjects'
      ? projectsMoveFoldersMutation
      : moveFoldersMutation,
    {
      onCompleted: async () => {
        refetch()
        enqueueSnackbar('Move Successful!', { variant: 'success' })
        dispatch({ type: CLOSE_CONFIRM_MOVE })
        dispatch({ type: CLEAR_SELECTED_ITEMS })
      },
      awaitRefetchQueries: true,
      onError: () => {
        enqueueSnackbar('Move Failed!', { variant: 'error' })
        dispatch({ type: CLOSE_CONFIRM_MOVE })
      }
    }
  )

  const [restoreFolders, { loading: restoreFoldersLoading }] = useMutation(
    restoreFoldersMutation,
    {
      onCompleted: async () => {
        await refetch()
        enqueueSnackbar('Restore Successful!', { variant: 'success' })
        dispatch({ type: CLOSE_CONFIRM_RESTORE })
        dispatch({ type: CLEAR_TRASH_ITEMS })
      },
      onError: () => {
        enqueueSnackbar('Restore Failed!', { variant: 'error' })
        dispatch({ type: CLOSE_CONFIRM_RESTORE })
      }
    }
  )

  const [restoreFiles, { loading: restoreFilesLoading }] = useMutation(
    restoreFilesMutation,
    {
      onCompleted: async () => {
        await refetch()
        enqueueSnackbar('Restore Successful!', { variant: 'success' })
        dispatch({ type: CLOSE_CONFIRM_RESTORE })
        dispatch({ type: CLEAR_TRASH_ITEMS })
      },
      onError: () => {
        enqueueSnackbar('Restore Failed!', { variant: 'error' })
        dispatch({ type: CLOSE_CONFIRM_RESTORE })
      }
    }
  )

  /*
  helpers
  */
  const handleCloseMove = () => dispatch({ type: CLOSE_CONFIRM_MOVE })
  const handleMove = () => {
    const files = []
    const folders = []
    selectedItems.forEach((item) =>
      item.type !== 'folder' ? files.push(item) : folders.push(item)
    )
    if (files.length)
      moveFiles({
        variables: {
          filePaths: files.map((file) => file.path),
          targetPath: moveDirectory,
          status: files.map((file) => file.status)
        }
      })
    if (folders.length)
      moveFolders({
        variables: {
          folderPaths: folders.map((folder) => folder.path),
          targetPath: moveDirectory
        }
      })
  }
  const handleCloseDelete = () => dispatch({ type: CLOSE_CONFIRM_DELETE })
  const handleCloseRestore = () => dispatch({ type: CLOSE_CONFIRM_RESTORE })
  const handleRestore = () => {
    const files = []
    const folders = []
    trashItems.forEach((item) =>
      item.type !== 'folder' ? files.push(item) : folders.push(item)
    )
    if (files.length)
      restoreFiles({
        variables: {
          filePaths: files.map((file) => file.path)
        }
      })
    if (folders.length)
      restoreFolders({
        variables: {
          folderPaths: folders.map((folder) => folder.path)
        }
      })
  }
  const handleListItemsClick = (text) => {
    if (listLinks[text].isExternal) window.open(listLinks[text].link, '_blank')
    else Router.push(listLinks[text].link)
  }

  const handleDirectoryChange = (path, name, id, type = 'folders') => {
    const { link, directoryData } = currentDirectory
    const outGoingdDirectoryData = directoryData
    outGoingdDirectoryData.push({ path, name, id, type })

    const directory = {
      path,
      name,
      id,
      type: type === 'businesses' ? 'projects' : 'folders',
      link: `${link}${name}/`,
      directoryData: outGoingdDirectoryData
    }

    dispatch({
      type: CHANGE_CURRENT_DIRECTORY,
      payload: directory
    })
  }

  return (
    <>
      <Grid container direction='row'>
        {currentDirectory && currentDirectory.type === 'businesses' && (
          <Grid item xs={2} sm={2} md={2}>
            <List>
              {partnerList.map((text) => (
                <ListItem key={text} disablePadding>
                  <Button onClick={() => handleListItemsClick(text)}>
                    <ListItemText primary={text} disableTypography />
                  </Button>
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
        <Grid item xs={10} sm={10} md={10}>
          {libraryType !== 'userProjects' && (
            <LibrarySortFilter
              directoryView={directoryView}
              dispatch={dispatch}
              filters={filters}
              currentDirectory={currentDirectory}
            />
          )}
          <LibraryBreadCrumbs
            currentDirectory={currentDirectory}
            libraryType={libraryType}
            role={role}
            showTrash={showTrash}
            dispatch={dispatch}
            onChange={(payload) =>
              dispatch({ type: CHANGE_CURRENT_DIRECTORY, payload })
            }
          />
          {updatedItems &&
          updatedItems?.files?.length + updatedItems?.folders?.length > 0 &&
          !projectsLoading ? (
            <LibraryContainer>
              {directoryView === 'grid' ? (
                <LibraryFilesGrid
                  selectedItems={selectedItems}
                  trash={trash}
                  trashItems={trashItems}
                  currentDirectory={currentDirectory}
                  handleDirectoryChange={handleDirectoryChange}
                  dispatch={dispatch}
                  {...updatedItems}
                  loading={loading && projectsLoading}
                  libraryType={libraryType}
                  refetch={refetch}
                />
              ) : (
                <LibraryFilesList
                  selectedItems={selectedItems}
                  trashItems={trashItems}
                  handleDirectoryChange={handleDirectoryChange}
                  currentDirectory={currentDirectory}
                  renameProject={renameProject}
                  dispatch={dispatch}
                  loading={loading}
                  {...items}
                />
              )}
              {updatedItems &&
                updatedItems.folders &&
                updatedItems.files &&
                updatedItems.files.length + updatedItems.folders.length < 1 &&
                !loading && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    minHeight={200}
                    alignItems='center'
                    justifyContent='center'
                  >
                    {currentDirectory &&
                      (currentDirectory.type === 'businesses' ? (
                        <>
                          <Typography variant='h5' align='center'>
                            You are not managing any businesses yet.
                          </Typography>
                        </>
                      ) : currentDirectory.type === 'projects' ? (
                        <>
                          <Typography variant='h5' align='center'>
                            Start by creating new projects.
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant='h5' align='center'>
                            No files in the current directory!
                          </Typography>
                          <Typography align='center'>
                            Start uploading files and have them stored for
                            lifetime so you can use them in your projects at any
                            time.
                          </Typography>
                        </>
                      ))}
                  </Box>
                )}
            </LibraryContainer>
          ) : (
            <Box display='flex' justifyContent='center' alignItems='center'>
              <CircularProgress />
            </Box>
          )}
        </Grid>
        <ConfirmationDialog
          open={confirmDelete}
          onClose={handleCloseDelete}
          message='Are you sure you want to delete selected assets(s)?'
          title='Delete Assets(s)'
          actionLabel='Yes, Delete'
          onConfirm={handleDelete}
          loading={deleteFilesLoading || deleteFoldersLoading}
        />
        {libraryType === 'userLibrary' ||
          (libraryType === 'userProjects' && (
            <>
              <ConfirmationDialog
                open={confirmMove}
                onClose={handleCloseMove}
                message='Please browse into the new path for the selected assets(s)'
                title='Move Assets(s)'
                actionLabel='Move, Here'
                onConfirm={handleMove}
                component={
                  libraryType === 'userProjects'
                    ? UserProjectsMoveView
                    : UserLibraryMoveView
                }
                loading={moveFilesLoading || moveFoldersLoading}
                refetch={refetch}
              />
              <ConfirmationDialog
                open={confirmRestore}
                onClose={handleCloseRestore}
                message='Are you sure you want to restore selected assets(s)?'
                title='Restore Assets(s)'
                actionLabel='Yes, Restore'
                onConfirm={handleRestore}
                loading={restoreFilesLoading || restoreFoldersLoading}
              />
            </>
          ))}
      </Grid>
    </>
  )
}

export default LibraryFiles
