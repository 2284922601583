import React, { useContext } from 'react'
import Folder from '@material-ui/icons/Folder'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { UserLibraryContext } from '../../providers/UserLibraryProvider'
import { UserProjectsContext } from '../../providers/UserProjectsProvider'
import { CHANGE_MOVE_PATH } from '../../providers/UserLibraryProvider/actions-types'
import { CHANGE_PROJECTS_MOVE_PATH } from '../../providers/UserProjectsProvider/action-types'

const ListViewItem = ({ name, type, path, libraryType }) => {
  /*
  context
  */
  const { dispatch } = useContext(UserLibraryContext)
  const { dispatch: projectsDispatch } = useContext(UserProjectsContext)
  /*
  helpers
  */

  const handleDoubleClick = () =>
    type === 'folder' && libraryType === 'userProjects'
      ? projectsDispatch({ type: CHANGE_PROJECTS_MOVE_PATH, payload: path })
      : dispatch({ type: CHANGE_MOVE_PATH, payload: path })

  const renderContent = (
    <Grid container>
      <Grid item xs>
        <Typography variant='subtitle1'>{name}</Typography>
      </Grid>
    </Grid>
  )

  return (
    <div>
      <ListItem
        disableGutters
        onDoubleClick={handleDoubleClick}
        alignItems='center'
      >
        <ListItemIcon>
          <Box display='flex' alignItems='center'>
            {/* <Checkbox
              edge='start'
              checked={false}
              tabIndex={-1}
              disableRipple
            /> */}
            <Folder />
          </Box>
        </ListItemIcon>
        <ListItemText primary={renderContent} />
      </ListItem>
    </div>
  )
}

export default ListViewItem
